exports.components = {
  "component---src-data-pages-404-index-tsx": () => import("./../../../src/data/pages/404/index.tsx" /* webpackChunkName: "component---src-data-pages-404-index-tsx" */),
  "component---src-data-pages-behandelen-index-jsx": () => import("./../../../src/data/pages/behandelen/index.jsx" /* webpackChunkName: "component---src-data-pages-behandelen-index-jsx" */),
  "component---src-data-pages-bijsluiters-index-jsx": () => import("./../../../src/data/pages/bijsluiters/index.jsx" /* webpackChunkName: "component---src-data-pages-bijsluiters-index-jsx" */),
  "component---src-data-pages-bronchosedal-gamma-index-jsx": () => import("./../../../src/data/pages/bronchosedal-gamma/index.jsx" /* webpackChunkName: "component---src-data-pages-bronchosedal-gamma-index-jsx" */),
  "component---src-data-pages-contact-index-jsx": () => import("./../../../src/data/pages/contact/index.jsx" /* webpackChunkName: "component---src-data-pages-contact-index-jsx" */),
  "component---src-data-pages-cookiebeleid-index-jsx": () => import("./../../../src/data/pages/cookiebeleid/index.jsx" /* webpackChunkName: "component---src-data-pages-cookiebeleid-index-jsx" */),
  "component---src-data-pages-fr-conseils-contre-la-toux-index-jsx": () => import("./../../../src/data/pages/fr/conseils-contre-la-toux/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-conseils-contre-la-toux-index-jsx" */),
  "component---src-data-pages-fr-contact-index-jsx": () => import("./../../../src/data/pages/fr/contact/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-contact-index-jsx" */),
  "component---src-data-pages-fr-gamme-bronchosedal-index-jsx": () => import("./../../../src/data/pages/fr/gamme-bronchosedal/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-gamme-bronchosedal-index-jsx" */),
  "component---src-data-pages-fr-index-jsx": () => import("./../../../src/data/pages/fr/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-index-jsx" */),
  "component---src-data-pages-fr-la-toux-index-jsx": () => import("./../../../src/data/pages/fr/la-toux/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-la-toux-index-jsx" */),
  "component---src-data-pages-fr-mentions-legales-index-jsx": () => import("./../../../src/data/pages/fr/mentions-legales/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-mentions-legales-index-jsx" */),
  "component---src-data-pages-fr-notices-index-jsx": () => import("./../../../src/data/pages/fr/notices/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-notices-index-jsx" */),
  "component---src-data-pages-fr-politique-en-matiere-de-cookies-index-jsx": () => import("./../../../src/data/pages/fr/politique-en-matiere-de-cookies/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-politique-en-matiere-de-cookies-index-jsx" */),
  "component---src-data-pages-fr-respect-de-la-vie-privee-index-jsx": () => import("./../../../src/data/pages/fr/respect-de-la-vie-privee/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-respect-de-la-vie-privee-index-jsx" */),
  "component---src-data-pages-fr-sitemap-index-jsx": () => import("./../../../src/data/pages/fr/sitemap/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-sitemap-index-jsx" */),
  "component---src-data-pages-fr-traiter-index-jsx": () => import("./../../../src/data/pages/fr/traiter/index.jsx" /* webpackChunkName: "component---src-data-pages-fr-traiter-index-jsx" */),
  "component---src-data-pages-hoesten-index-jsx": () => import("./../../../src/data/pages/hoesten/index.jsx" /* webpackChunkName: "component---src-data-pages-hoesten-index-jsx" */),
  "component---src-data-pages-index-jsx": () => import("./../../../src/data/pages/index.jsx" /* webpackChunkName: "component---src-data-pages-index-jsx" */),
  "component---src-data-pages-privacybeleid-index-jsx": () => import("./../../../src/data/pages/privacybeleid/index.jsx" /* webpackChunkName: "component---src-data-pages-privacybeleid-index-jsx" */),
  "component---src-data-pages-sitemap-index-jsx": () => import("./../../../src/data/pages/sitemap/index.jsx" /* webpackChunkName: "component---src-data-pages-sitemap-index-jsx" */),
  "component---src-data-pages-tips-tegen-hoesten-index-jsx": () => import("./../../../src/data/pages/tips-tegen-hoesten/index.jsx" /* webpackChunkName: "component---src-data-pages-tips-tegen-hoesten-index-jsx" */),
  "component---src-data-pages-wettelijke-verklaring-index-jsx": () => import("./../../../src/data/pages/wettelijke-verklaring/index.jsx" /* webpackChunkName: "component---src-data-pages-wettelijke-verklaring-index-jsx" */)
}

